<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">添加提醒</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<!-- <el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row >
					<el-col :span="24">
						<el-form-item label="宠物名称" required>
							<el-input v-model="petMsg.nickName" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form> -->
			<div style="width:800px;min-height: 500px;">
				<el-table :data="productList" height="100%">
					<el-table-column label="产品名称" min-width="80">
						<template slot-scope="scope">
							{{scope.row.productName}}
						</template>
					</el-table-column>
					<el-table-column label="提醒时间" min-width="80">
						<template slot-scope="scope">
							<el-input v-model="scope.row.day" @input="inputDay(scope.row)" style="width:150px;">
								<template slot="append">天后</template>
							</el-input>
						</template>
					</el-table-column>
					<el-table-column label="宠物" min-width="80">
						<template slot-scope="scope">
							<el-select
							v-model="scope.row.petInfo.petId"
							placeholder="请选择"
							>
								<el-option popper-class="select" v-for="item in petList" :key="item.id" :label="item.nickName"
								:value="item.id"></el-option>
							</el-select>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">稍后处理</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				productList:[],
				petList:[],
			}
		},
		computed:{
			
		},
		props:{
			orderObj:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			this.getUserInfo()
			let productList = []
			for(let i=0;i<this.orderObj.orderItemList.length;i++){
				if(this.orderObj.orderItemList[i].buyBack===true){
					let item = this.orderObj.orderItemList[i]
					this.$set(item,'day',7)
					productList.push(item)
				}
			}
			this.productList = productList
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let buyBackList = []
				for(let i=0;i<this.productList.length;i++){
					if(this.$public.isNull(this.productList[i].day)){
						this.$message({
							type: 'error',
							message: '请完善提醒时间!'
						});
						return 
					}
					if(this.$public.isNull(this.productList[i].petInfo.petId)){
						this.$message({
							type: 'error',
							message: '请完善宠物信息!'
						});
						return 
					}
					buyBackList.push({
						"productId": this.productList[i].productId,
						"productName": this.productList[i].productName,
						"days":  Number(this.productList[i].day),
						"petId": this.productList[i].petInfo.petId,
						"petName": this.$public.getMatchingItem(this.petList,'id',this.productList[i].petInfo.petId).nickName,
					})
				}
				let params = {
					"orderNo": this.orderObj.orderNo,
					"buyBackList": buyBackList
				}
				let loading = this.$loading()
				this.$http.post("/business/BuyBack/Insert",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.$emit("submit",{type:'success',data:''})
					}
				})
			},
			// 获取会员信息
			getUserInfo(){
				let params = {
					id:this.orderObj.userInfo.userId,
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						if(!this.$public.isNull(res.data.pets_info)){
							this.petList = res.data.pets_info
						}
					}
				})
			},
			// 修改数量
			inputDay(row){
				row.day = this.$public.replaceToDouble(row.day)
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
